<template>
  <div class="details_apply">
    <div class="top_bg">
      <img v-if="detailsList.order.exa_status == '0'" src="../../assets/images/Apply/img_shz_.png" alt="" />
      <img v-if="detailsList.order.exa_status == '1'" src="../../assets/images/Apply/img_shtg.png" alt="" />
      <img v-if="detailsList.order.exa_status == '2'" src="../../assets/images/Apply/img_shwtg_.png" alt="" />
      <img v-if="detailsList.order.exa_status == '3'" src="../../assets/images/Apply/img_ysx_.png" alt="" />
      <img v-if="detailsList.order.exa_status == '4'" src="../../assets/images/Apply/img_qydwc.png" alt="" />
    </div>
    <div class="formdata_box">
      <div class="bx_item">
        <div class="fs-40">服务说明</div>
        <div class="box fs-28 rich-text" v-html="decodeHTML(detailsList.insurance_type.instype_explain)"></div>
      </div>
      <div class="bx_item">
        <div class="fs-40">手机照片</div>
        <div class="img_detail">
          <img :src="detailsList.order.tem_img" alt="" @click="$imagePreview([detailsList.order.tem_img])" />
        </div>
      </div>

      <div class="bx_item">
        <div class="fs-40 mt-50">用户信息</div>
        <div class="item_detail g-flex-jb g-flex-ac" v-for="item in columns" :key="item.id" v-show="!(
            item.label === '裸机价格' &&
            detailsList.insurance_type.instype_id === 3
          )
          ">
          <div class="left fs-28">{{ item.label }}</div>
          <div class="right fs-28 right-value" :class="item.label == '裸机价格' ? 'color_red' : ''">
            <span v-if="item.label == '裸机价格'">￥</span><span class="rich-text" v-html="item.value || '-'"></span>
          </div>
        </div>
      </div>

      <div class="bx_item" v-if="detailsList.order.tem_elsie">
        <div class="fs-40" style="margin-bottom: 10px">
          爱思助手/沙漏验机截图
        </div>
        <div class="img_detail">
          <img :src="detailsList.order.tem_elsie" alt="" @click="$imagePreview([detailsList.order.tem_elsie])" />
        </div>
      </div>
      <div class="bx_item" v-if="detailsList.order.video">
        <div class="fs-40" style="margin-bottom: 10px">
          {{ detailsList.order.tem_elsie ? "视频" : "非本店购机视频" }}
        </div>
        <video :src="detailsList.order.video" class="video" controls></video>
      </div>
      <div class="bx_item">
        <div class="fs-40">订单信息</div>
        <div class="item_order">
          <div class="mt-30 g-flex-ac" v-for="item in columnsOrder" :key="item.id">
            <div class="left mt-10 ml-30 fs-28">
              <span>{{ item.label }}</span>
            </div>
            <div class="right mt-10 fs-28">
              <span v-if="item.label == '注册协议'" class="color_b" @click="clickProtocol">《{{ item.value }}服务协议》</span>
              <span v-else>{{ item.value }}</span>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div v-if="detailsList.order.is_renewal" class="btn_edit">
        <my-button @click="jumpRenew()">服务续费</my-button>
      </div>
      <div class="mt-50" v-show="$route.query.canOperate !== '0'">
        <!-- 0待审核 1审核通过 2审核不通过 3 失效 4完成 -->
        <div class="btn_edit" v-if="detailsList.order.exa_status == '0'">
          <!-- <my-button @click="jumpEdit(0)">编辑订单</my-button> -->
        </div>
        <!--        &&detailsList.insurance_type.instype_name!='年年焕新计划'-->
        <div class="btn_edit1 g-flex-jb" v-if="detailsList.order.exa_status == '1'">
          <my-button @click="jumpEdit(1)" type="default" :width="(750 - 90) / 2">更换IMEI</my-button><!-- //跳转更换imei页面 -->
          <my-button @click="jumpEdit(2)" type="primary" :width="(750 - 90) / 2">申请理赔</my-button><!-- //跳转理赔申请页面 -->
        </div>
        <div class="btn_edit" v-if="detailsList.order.exa_status == '2'">
          <my-button @click="jumpEdit(0)">编辑订单</my-button>
        </div>
        <div class="btn_edit3 g-flex-jb" @click="jumpEdit(3)" v-if="detailsList.order.exa_status == '3' ||
          detailsList.order.exa_status == '4'
          ">
          <div class="error_his_le g-flex-jb ml-40 fs-28">
            <img src="../../assets/images/Apply/zu9.png" alt="" />
            <div>查看关联申请记录</div>
          </div>
          <div class="error_his_ri mr-40 g-flex-jb fs-28">2条＞</div>
        </div>
        <!-- <div class="btn_edit" v-if="detailsList.order.exa_status=='4'"></div> -->
      </div>
    </div>
    <van-popup round v-model="showText">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="wra_tittle">
            {{ this.$store.state.document_title }}服务协议
          </div>
          <div class="text rich-text" v-html="decodeHTML(this.detailsList.insurance_type.instype_agree)"></div>
          <div class="wra_btn" @click="showText = false">知道了</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import { decodeHTML } from "@/common/filters";

export default {
  name: "detailsApply",
  data() {
    return {
      showText: false,
      detailsList: {
        insurance_type: {},
        order: {},
      },
      columns: [],
      columnsOrder: [],
    };
  },
  computed: {
    decodeHTML() {
      return (str) => {
        return decodeHTML(str);
      };
    },
  },
  created() {
    console.log(this.$route.query);
    this.$http.getOrderInfo({ order_id: this.$route.query.id }).then((res) => {
      console.log(res);
      this.detailsList = res.data;

      this.columns = [
        { label: "姓名", value: this.detailsList.order.name },
        {
          label: "身份证类型",
          value:
            this.detailsList.order.id_card_type == 1
              ? "大陆身份证"
              : "港澳身份证",
        },
        { label: "身份证号", value: this.detailsList.order.id_card },
        { label: "手机号码", value: this.detailsList.order.phone },
        { label: "IMEI号或序列号", value: this.detailsList.order.imei },
        { label: "保卡卡密", value: this.detailsList.order.card_secret },
        { label: "手机品牌", value: this.detailsList.order.brand },
        { label: "手机型号", value: this.detailsList.order.model_name },
        { label: "手机规格", value: this.detailsList.order.ram_size },
      ];
      if (this.detailsList.order.son.length) {
        let son = "";
        this.detailsList.order.son.forEach((item) => {
          son += `<p>${item.son_name}（${item.years}）</p>`;
        });
        this.columns.push({ label: "子险种", value: son });
      }
      if (
        this.detailsList.order.instype_id === 6 ||
        this.detailsList.order.instype_id === 7
      ) {
        this.columns.splice(4, 1);
        this.columns.push({
          label: "开始期限",
          value: this.detailsList.order.term_time_start,
        });
        this.columns.push({
          label: "结束期限",
          value: this.detailsList.order.term_time_end,
        });
        this.columns.push({
          label: "延保生效时间",
          value: this.detailsList.order.term_time_start2,
        });
        this.columns.push({
          label: "延保失效时间",
          value: this.detailsList.order.term_time_end2,
        });
      }
      //todo
      if (this.detailsList.order.type === 2) {
        this.columns.push({
          label: "网络制式",
          value: this.detailsList.order.net_name,
        });
        this.columns.push({
          label: "裸机价格",
          value: this.detailsList.order.bare_price,
        });
      }

      //放最后
      this.columns.push({
        label: "回复内容",
        value: this.detailsList.order.fail_exp,
      });

      if (
        this.detailsList.order.exa_status == "0" ||
        this.detailsList.order.exa_status == "2"
      ) {
        this.columnsOrder = [
          { label: "订单编号", value: this.detailsList.order.order_sn },
          { label: "提交时间", value: this.detailsList.order.add_time },
          // {label: '审核通过时间', value: this.detailsList.order.exa_time},
          // {label: '生效期限', value: this.detailsList.order.term_time },
          { label: "接待门店", value: this.detailsList.order.shop_name },
          { label: "接待人员", value: this.detailsList.order.shop_member_name },
          {
            label: "注册协议",
            value: this.detailsList.insurance_type.instype_name,
          },
        ];
      } else {
        this.columnsOrder = [
          { label: "订单编号", value: this.detailsList.order.order_sn },
          { label: "提交时间", value: this.detailsList.order.add_time },
          { label: "审核通过时间", value: this.detailsList.order.exa_time },
          { label: "生效期限", value: this.detailsList.order.term_time },
          { label: "接待门店", value: this.detailsList.order.shop_name },
          { label: "接待人员", value: this.detailsList.order.shop_member_name },
          {
            label: "注册协议",
            value: this.detailsList.insurance_type.instype_name,
          },
        ];
        if (this.detailsList.order.vice_card_name) {
          // this.columnsOrder.push(
          //     {label: '附加服务', value: this.detailsList.order.vice_card_name},
          //     {label: '附加服务生效期限', value: this.detailsList.order.term_time_start2+'至'+this.detailsList.order.term_time_end2},
          // )
          this.columnsOrder.splice(1, 0, {
            label: "附加服务",
            value: this.detailsList.order.vice_card_name,
          });
          this.columnsOrder.splice(5, 0, {
            label: "附加服务生效期限",
            value:
              this.detailsList.order.term_time_start2 +
              "至" +
              this.detailsList.order.term_time_end2,
          });
        }
      }
    });
  },
  methods: {
    //协议弹窗
    clickProtocol() {
      this.showText = true;
    },
    //编辑订单
    jumpEdit(val) {
      let { instype_id } = this.detailsList.insurance_type;
      let { order } = this.detailsList;
      let type;
      switch (val) {
        case 0:
          this.$router.push({
            path: "/RightApply/addApply",
            query: { type: this.detailsList.order.type, isEdit: "1" },
          });
          sessionStorage.setItem("applyInfo", JSON.stringify(this.detailsList));
          break;
        case 1:
          //更换IMEI
          order.instype_name = this.detailsList.insurance_type.instype_name;
          order.instype_id = this.detailsList.insurance_type.instype_id;
          sessionStorage.setItem("data", JSON.stringify(order));
          this.$router.push({ name: "ReplaceIMEIApply" });
          break;
        case 2:
          switch (this.detailsList.insurance_type.instype_id) {
            case 1:
              type = "brokenScreen";
              break;
            case 2:
              type = "android";
              break;
            case 3:
              type = "iphone";
              break;
            case 4:
              type =
                this.detailsList.order.type === 1
                  ? "iphoneYearChange"
                  : "androidYearChange";
              break;
            case 5:
              type = "smartChoice";
              break;
            case 6:
              type = "huawei";
              break;
            case 7:
              type = "iphoneSmart";
              break;
            case 8:
              type = "yanbaofuwu";
              break;
            case 9:
              type = "dianchifuwu";
              break;
            //todo 新增保险要改的地方
            case 10:
              type = "upServe";
              break;
            case 11:
              type = "houdun";
              break;
            case 13:
              type = "zuansu";
              break;
          }
          this.$router.push({
            name: "ClaimSettlementApply",
            params: { type },
            // 传imei改为传order_id=== imei: order.imei, 
            query: {instype_id,id: order.order_id },
          });
          break;
        default:
          this.$http
            .getClaimsList({ order_id: this.$route.query.id })
            .then((res) => {
              console.log(res.data);
              if (res.data.length > 0) {
                this.$router.push({ path: "./related" }); //跳转查看关联申请记录 -->
                sessionStorage.setItem("relatData", JSON.stringify(res.data));
              } else {
                Toast("暂无数据");
              }
            });
          break;
      }
    },
    // 续保
    jumpRenew() {
      this.$router.push({
        path: '/RightApply/renew',
        query: {
          order_id: this.detailsList.order.order_id
        }
      })
    }
  },
};
</script>

<style scoped lang="less">
.details_apply {
  .top_bg {
    position: relative;
    width: 100vw;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .btn_edit {
    margin-bottom: 80px;
    width: 80vw;
    margin: 0 auto;
  }

  .btn_edit1 {
    margin-bottom: 80px;
    width: 92vw;
    margin: 0 auto;
  }

  .btn_edit3 {
    margin-bottom: 80px;

    .error_his_le {
      color: #577bb5;
      width: 280px;
      height: 40px;

      img {
        width: 38px;
        height: 38px;
      }
    }

    .error_his_ri {
      color: #577bb5;
      height: 40px;
    }
  }

  .formdata_box {
    position: absolute;
    top: 140px;
    background: white;
    width: 100vw;
    padding: 20px 0 50px 0;
    border-radius: 40px 40px 0px 0px;

    .bx_item {
      width: calc(100vw - 60px);
      margin: 40px auto;

      .box {
        line-height: 44px;
        margin-top: 20px;
        padding: 28px 25px;
        background: #f6f8fe;
        border-radius: 16px;
        color: #333333;
        font-family: PingFang SC;
        font-weight: 400;
      }

      .item_order {
        min-height: 370px;
        background: #f6f8fe;
        border-radius: 16px;
        padding-bottom: 20px;
      }

      .item_detail {
        min-height: 120px;
        line-height: 120px;
        padding: 20px 0;
        opacity: 0.98;
        border-bottom: 1px solid #eeeeee;

        .color_red {
          span {
            color: #e86161;
          }
        }
      }

      .color_b {
        margin-left: -10px;
        color: #577bb5;
      }

      .left {
        width: 240px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #666666;
      }

      .right {
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
    }

    .img_detail {
      margin: 20px auto 0 auto;
      width: calc(100vw - 60px);
      height: 345px;
      border-radius: 16px;

      img {
        width: calc(100vw - 60px);
        height: 345px;
        object-fit: cover;
        border-radius: 20px;
      }
    }

    .video {
      width: calc(100vw - 60px);
      height: 345px;
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 90vw;
  min-height: 30vh;
  max-height: 90vh;
  overflow-y: auto;
  background-color: #f6f8fe;
  border-radius: 20px;

  .wra_tittle {
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #323234;
    line-height: 60px;
    width: 90vw;
    margin: 20px auto;
    text-align: center;
  }

  div:nth-child(2) {
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    max-height: 70vh;
    overflow-y: scroll;
    width: 80vw;
    margin: 0 auto;
    color: #333333;
    line-height: 44px;
  }

  .wra_btn {
    width: 400px;
    height: 80px;
    background: #577bb5;
    box-shadow: 0px 6px 15px 1px rgba(87, 123, 181, 0.3);
    border-radius: 40px;
    text-align: center;
    color: white;
    line-height: 80px;
    margin: 30px auto;
  }
}

.right-value {
  max-width: 500px;
}

::v-deep .rich-text img {
  max-width: 100%;
}
</style>
